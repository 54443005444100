import { Link, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { useTranslation } from 'react-i18next';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import { useState } from 'react';
import ApplyVacancy from './VacancyForm';
import dayjs from 'dayjs';

export default function VacancyDetail() {
  const { id } = useParams();
  const { t } = useTranslation(['components_header_trn']);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [apply, setApply] = useState(false);

  const collection = {
    locale: currentLanguage,
  };

  const { data: vacancyData } = useAxiosQuery({
    url: `/portal-careers/get-career/${id}`,
    method: 'GET',
    params: collection,
  });

  const { data: vacancyHeader } = useAxiosQuery({
    url: `/portal-interactions/get-sections/benefit`,
    method: 'GET',
    params: collection,
  });

  if (vacancyData) {
    return (
      <>
        <Nav />
        <div className='container-fluid page-header py-5'>
          <div className='container text-center py-5'>
            <p className='display-6 text-white mb-2 animated slideInDown'>
              {vacancyHeader?.data?.map((item) => item?.title)}
            </p>
          </div>
        </div>

        <div className='container-fluid py-3 my-3'>
          <div className='container py-3'>
            <div className='row g-5'>
              <div
                className='col-lg-5 col-md-6 col-sm-12 wow fadeIn'
                data-wow-delay='.3s'
              >
                <div className='h-100 position-relative'>
                  <div className='vacancy-item bg-light'>
                    <div className='p-4 text-start '>
                      <div className=' bg-light'>
                        <div className='p-4 text-start'>
                          <div className=' text-start'>
                            <ul className='list-unstyled ms-2 ms-md-3 ms-lg-4'>
                              <li className='mb-2'>
                                <i className='bi bi-check-circle txt-primary fs-5'></i>
                                <span className='ms-2'>
                                  <strong className=''>
                                    {' '}
                                    {t('Experience')}:{' '}
                                  </strong>
                                  {vacancyData?.requirements?.experience}
                                </span>
                              </li>
                              <li className='mb-2'>
                                <i className='bi bi-check-circle txt-primary fs-5'></i>
                                <span className='ms-2'>
                                  <strong className=''>
                                    {t('Qualification')} :
                                  </strong>
                                  {vacancyData?.requirements?.qualification}
                                </span>
                              </li>
                              <li className='mb-2'>
                                <i className='bi bi-check-circle txt-primary fs-5'></i>
                                <span className='ms-2'>
                                  <strong className=''>
                                    {t('Term Of Employment')}:{' '}
                                  </strong>
                                  {vacancyData?.termOfEmployment}
                                </span>
                              </li>
                              <li className='mb-2'>
                                <i className='bi bi-check-circle txt-primary fs-5'></i>
                                <span className='ms-2'>
                                  <strong className=''>
                                    {t('Vacancy Type')}:
                                  </strong>{' '}
                                  {vacancyData?.vacancyType}
                                </span>
                              </li>
                              <li className='mb-2'>
                                <i className='bi bi-check-circle txt-primary fs-5'></i>
                                <span className='ms-2'>
                                  <strong className=''>
                                    {t('Work Schedule')} :
                                  </strong>
                                  {vacancyData?.workSchedule}
                                </span>
                              </li>
                              <li className='mb-2'>
                                <i className='bi bi-check-circle txt-primary fs-5'></i>
                                <span className='ms-2'>
                                  <strong className=''>{t('Salary')} : </strong>
                                  {vacancyData?.salaryRange === '0'
                                    ? 'Negotiable'
                                    : `$${vacancyData?.salaryRange}`}
                                </span>
                              </li>
                              <li className='mb-2'>
                                <i className='bi bi-check-circle txt-primary fs-5'></i>
                                <span className='ms-2'>
                                  <strong className=''>
                                    {t('Deadline')}:{' '}
                                  </strong>
                                  {dayjs(vacancyData?.endDate).format(
                                    'DD MMM YYYY'
                                  )}
                                </span>
                              </li>
                            </ul>

                            <div className='d-flex justify-content-end align-items-center mb-10'>
                              {!apply ? (
                                <Link
                                  className={`btn btn-md text-capitalize size-lg  d-flex justify-content-center btn-primary text-white rounded-pill`}
                                  onClick={() => {
                                    setApply(true);
                                  }}
                                >
                                  {t(`Apply`, { ns: 'components_header_trn' })}
                                </Link>
                              ) : (
                                <Link
                                  to='/vacancy'
                                  className='btn text-capitalize size-lg txt-sm d-flex justify-content-center btn-primary text-white rounded-pill'
                                >
                                  <i className='bi bi-arrow-90deg-left me-2'></i>
                                  {t(`Back`, { ns: 'components_header_trn' })}
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {apply ? (
                <div
                  className='col-lg-7 col-md-6 col-sm-12 wow fadeIn'
                  data-wow-delay='.5s'
                >
                  <ApplyVacancy vacancyCode={vacancyData?.code} />
                </div>
              ) : (
                <div
                  className='col-lg-7 col-md-6 col-sm-12 wow fadeIn'
                  data-wow-delay='.5s'
                >
                  <h3 className='mb-4 txt-primary'>
                    {vacancyData?.title?.[currentLanguage]}
                  </h3>

                  {vacancyData?.description?.[currentLanguage] && (
                    <>
                      <div className='mb-4'>
                        <p>{vacancyData?.description?.[currentLanguage]}</p>
                      </div>
                    </>
                  )}

                  {vacancyData?.benefit?.[currentLanguage] && (
                    <>
                      <h4 className='mt-4'>
                        {t(`Benefits that you will get`)}
                      </h4>
                      <div className='mb-4'>
                        <p>{vacancyData?.benefit?.[currentLanguage]}</p>
                      </div>
                    </>
                  )}

                  {vacancyData?.requirements?.qualification && (
                    <>
                      <h4 className='mt-4'>{t(`Qualifications`)}</h4>
                      <div className='mb-4'>
                        <p>{vacancyData?.requirements?.qualification}</p>
                      </div>
                    </>
                  )}

                  {vacancyData?.requirements?.skills && (
                    <>
                      <h4 className='mt-4'>{t(`Skills`)}</h4>
                      <div className='mb-4'>
                        <p>{vacancyData?.requirements?.skills}</p>
                      </div>
                    </>
                  )}

                  {vacancyData?.note && (
                    <>
                      <h4 className='mt-4'>{t(`Responsibilities`)}</h4>
                      <div className='mb-4'>
                        <p>{vacancyData?.note}</p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
