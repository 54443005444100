import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseUrl = import.meta.env.VITE_PUBLIC_APP_API;
const x_api_key = import.meta.env.VITE_X_API_KEY;

const ContactForm = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Full Name is required';
    if (!formData.email) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email Address is invalid';
    }
    if (!formData.phone) {
      errors.phone = 'Phone Number is required';
    } else if (formData.phone.length < 10) {
      errors.phone = 'Phone Number is invalid';
    }
    if (!formData.subject) errors.subject = 'Subject is required';
    if (!formData.message) errors.message = 'Message is required';
    return errors;
  };

  const notify = () => toast('Message sent successfully!');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    axios
      .post(`${baseUrl}/portal-interactions/create-contact-us`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': x_api_key,
        },
      })
      .then((response) => {
        console.log(response);
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
        notify();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className='container-fluid py-4 mt-2'>
      <div className='container py-4'>
        <div
          className='text-center mx-auto pb-5 wow fadeIn'
          data-wow-delay='.3s'
          style={{ maxWidth: '600px' }}
        >
          <h5 className='txt-primary'>
            {t(`Get In Touch`, { ns: 'components_header_trn' })}
          </h5>
          <h1 className='mb-3'>
            {t(`Contact for any query`, {
              ns: 'components_header_trn',
            })}
          </h1>
        </div>
        <div className='contact-detail position-relative'>
          <div className='row g-5 mb-5 justify-content-center'>
            <div className='col-xl-4 col-lg-6 wow fadeIn' data-wow-delay='.3s'>
              <div className='d-flex bg-light p-3 rounded'>
                <div
                  className='flex-shrink-0 btn-square bg-secondary rounded-circle'
                  style={{ width: '64px', height: '64px' }}
                >
                  <i className='fas fa-map-marker-alt text-white'></i>
                </div>
                <div className='ms-3'>
                  <h4 className='txt-primary'>
                    {t(`Address`, {
                      ns: 'components_header_trn',
                    })}
                  </h4>
                  <a
                    href='https://maps.app.goo.gl/pucefZmSJgThUMte8'
                    target='_blank'
                    className='h5'
                  >
                    {t(`Gurd Shola, Addis Ababa, Ethiopia`, {
                      ns: 'components_header_trn',
                    })}
                  </a>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6 wow fadeIn' data-wow-delay='.5s'>
              <div className='d-flex bg-light p-3 rounded'>
                <div
                  className='flex-shrink-0 btn-square bg-secondary rounded-circle'
                  style={{ width: '64px', height: '64px' }}
                >
                  <i className='fa fa-phone text-white'></i>
                </div>
                <div className='ms-3'>
                  <h4 className='txt-primary'>
                    {t(`Call Us`, {
                      ns: 'components_header_trn',
                    })}
                  </h4>
                  <a className='h5' href='tel:+251911114048' target='_blank'>
                    +2519 1111 4048
                  </a>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6 wow fadeIn' data-wow-delay='.7s'>
              <div className='d-flex bg-light p-3 rounded'>
                <div
                  className='flex-shrink-0 btn-square bg-secondary rounded-circle'
                  style={{ width: '64px', height: '64px' }}
                >
                  <i className='fa fa-envelope text-white'></i>
                </div>
                <div className='ms-3'>
                  <h4 className='txt-primary'>
                    {t(`Email Us`, {
                      ns: 'components_header_trn',
                    })}
                  </h4>
                  <a
                    className='h5'
                    href='mailto:info@kachmale.com'
                    target='_blank'
                  >
                    info@kachamale.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='row g-5'>
            <div className='col-lg-6 wow fadeIn' data-wow-delay='.3s'>
              <div className='h-100 rounded contact-map'>
                <iframe
                  className='rounded w-100 h-100'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.4862208326495!2d38.820051299999996!3d9.019332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b855ff3370945%3A0xbd78b0238610b93!2sKachamale%20Technologies!5e0!3m2!1sen!2set!4v1710450983517!5m2!1sen!2set'
                  // width="600"
                  // height="450"
                  style={{ border: '0' }}
                  allowFullScreen={true}
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
            </div>
            <div className='col-lg-6 wow fadeIn' data-wow-delay='.5s'>
              <form onSubmit={handleSubmit}>
                <div className=' rounded contact-form'>
                  <div className='mb-4'>
                    <input
                      type='text'
                      className='form-control py-3'
                      placeholder={t('Full Name', {
                        ns: 'components_header_trn',
                      })}
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                    {errors.name && (
                      <p className='text-danger'>{errors.name}</p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <input
                      type='phone'
                      className='form-control border-1 py-3'
                      placeholder={t('Phone Number', {
                        ns: 'components_header_trn',
                      })}
                      value={formData.phone}
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    />
                    {errors.phone && (
                      <p className='text-danger'>{errors.phone}</p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <input
                      type='email'
                      className='form-control border-1 py-3'
                      placeholder={t('Email Address', {
                        ns: 'components_header_trn',
                      })}
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                    {errors.email && (
                      <p className='text-danger'>{errors.email}</p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <input
                      type='text'
                      className='form-control border-1 py-3'
                      placeholder={t('Subject', {
                        ns: 'components_header_trn',
                      })}
                      value={formData.subject}
                      onChange={(e) =>
                        setFormData({ ...formData, subject: e.target.value })
                      }
                    />
                    {errors.subject && (
                      <p className='text-danger'>{errors.subject}</p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <textarea
                      className='w-100 form-control border-1 py-3'
                      rows='5'
                      cols='10'
                      placeholder={t('Message', {
                        ns: 'components_header_trn',
                      })}
                      value={formData.message}
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                    ></textarea>
                    {errors.message && (
                      <p className='text-danger'>{errors.message}</p>
                    )}
                  </div>
                  <div className='text-start'>
                    <button
                      className='btn bg-primary text-white py-3 px-5'
                      type='button'
                      onClick={handleSubmit}
                    >
                      {t(`Send Message`, { ns: 'components_header_trn' })}
                    </button>
                  </div>
                </div>
              </form>
              <ToastContainer
                position='bottom-right'
                style={{ color: 'white' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
