import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import LanguageSwitcher from './LanguageChanger';

const Nav = () => {
  const { t } = useTranslation();

  const collection = {
    count: true,
    // locale: currentLanguage,
  };

  const { data: vacancyData } = useAxiosQuery({
    url: '/portal-careers/get-careers',
    method: 'GET',
    params: collection,
  });

  return (
    <>
      {/* <!-- Topbar Start --> */}
      <div className='container-fluid bg-dark py-2 d-none d-md-flex'>
        <div className='container'>
          <div className='d-flex justify-content-between topbar'>
            <div className='top-info'>
              <small className='me-3 text-white-50'>
                <a
                  href='https://maps.app.goo.gl/pucefZmSJgThUMte8'
                  target='_blank'
                >
                  <i className='fas fa-map-marker-alt me-2 text-dander'></i>
                </a>
                {t(`Gurd Shola, Addis Ababa, Ethiopia`, {
                  ns: 'components_header_trn',
                })}
              </small>
              <small className='me-3 text-white-50'>
                <a href='mailto:info@kachamale.com'>
                  <i className='fas fa-envelope me-2 text-dander'></i>
                </a>
                info@kachamale.com
              </small>
            </div>
            <div id='note' className='text-white d-none d-xl-flex'>
              <small>
                {t(`Note : We help you to Grow your Business`, {
                  ns: 'components_header_trn',
                })}
              </small>
            </div>
            <div className='top-link'>
              <a
                href='https://www.facebook.com/profile.php?id=61555678241096'
                target='_blank'
                className='bg-light nav-fill btn btn-sm-square rounded-circle'
                data-bs-toggle='tooltip'
                title='Follow On Facebook'
              >
                <i className='fab fa-facebook-f txt-primary'></i>
              </a>
              {/* <a
                href=""
                className="bg-light nav-fill btn btn-sm-square rounded-circle"
                data-bs-toggle="tooltip"
                title="Follow On Telegram"
              >
                <i className="fab fa-telegram txt-primary"></i>
              </a> */}
              <a
                href='https://twitter.com/KachamaleTech'
                target='_blank'
                className='bg-light nav-fill btn btn-sm-square rounded-circle'
                data-bs-toggle='tooltip'
                title='Follow On Twitter'
              >
                <i className='fab fa-twitter txt-primary'></i>
              </a>
              <a
                href='https://www.instagram.com/kachamaletech/'
                target='_blank'
                className='bg-light nav-fill btn btn-sm-square rounded-circle'
                data-bs-toggle='tooltip'
                title='Follow On Instagram'
              >
                <i className='fab fa-instagram txt-primary'></i>
              </a>
              <a
                href='https://www.linkedin.com/in/kachamale-technologies-7aa4622aa/'
                target='_blank'
                className='bg-light nav-fill btn btn-sm-square rounded-circle me-0'
                data-bs-toggle='tooltip'
                title='Follow On Linkedin'
              >
                <i className='fab fa-linkedin-in txt-primary'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End -->*/}

      {/* <!-- Navbar Start -->  */}
      <div className='container-fluid bg-primary'>
        <div className=''>
          <nav className='navbar navbar-dark navbar-expand-lg py-2 '>
            <NavLink
              to='/'
              className='navbar-brand'
              aria-label='Kachamale Technologies'
            >
              <img
                src='/img/longlogo3x.png'
                width='400px'
                className='d-none d-sm-block'
                alt='Kachamale Logo'
              />
              <img
                src='/img/white-logo2x.png'
                width='30px'
                className='d-block d-sm-none'
                alt='Kachamale Logo'
              />
            </NavLink>
            <div className=' d-flex'>
              <div className='navbar-toggler me-0 #navbarCollapse Menu'>
                <LanguageSwitcher />
              </div>
              <button
                type='button'
                className='navbar-toggler me-0'
                data-bs-toggle='collapse'
                data-bs-target='#navbarCollapse'
                aria-label='Menu'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
            </div>
            <div
              className='collapse navbar-collapse bg-transparent'
              id='navbarCollapse'
            >
              <div className='navbar-nav ms-auto ml-xl-auto p-0'>
                <NavLink to='/' className='nav-item nav-link'>
                  {t(`Home`)}
                </NavLink>
                <NavLink to='/services' className='nav-item nav-link'>
                  {t(`Services`)}
                </NavLink>
                <NavLink to='/projects' className='nav-item nav-link'>
                  {t(`Projects`)}
                </NavLink>
                <NavLink to='/about' className='nav-item nav-link'>
                  {t(`About`)}
                </NavLink>
                <NavLink to='/portfolio' className='nav-item nav-link'>
                  {t(`Portfolio`)}
                </NavLink>
                <NavLink to='/contact' className='nav-item nav-link'>
                  {t(`Contact`)}
                </NavLink>
                <NavLink
                  to='/vacancy'
                  className='nav-item nav-link position-relative'
                >
                  {t(`Vacancy`)}
                  {vacancyData?.count > 0 && (
                    <span
                      style={{
                        backgroundColor: '#9c2005',
                        color: 'white',
                        fontSize: '0.75rem', // Adjust font size for the badge
                        padding: '0.2rem 0.4rem', // Control badge size with padding
                        position: 'absolute',
                        top: '12px', // Adjust the vertical positioning
                        right: '-5px', // Adjust the horizontal positioning
                        transform: 'translate(50%, -50%)', // Slight adjustment to position relative to the text
                      }}
                      className='badge rounded-pill'
                    >
                      {vacancyData?.count}
                    </span>
                  )}
                </NavLink>
              </div>
            </div>
            <div className='d-none d-xl-flex flex-shirink-0 '>
              <div
                id='phone-tada'
                className='d-flex align-items-center justify-content-center me-4'
              >
                <a href='' className='position-relative animated tada infinite'>
                  <i className='fa fa-phone-alt text-white fa-2x'></i>
                  <div
                    className='position-absolute'
                    style={{ top: '-7px', left: '20px' }}
                  >
                    <span>
                      <i className='fa fa-comment-dots text-secondary'></i>
                    </span>
                  </div>
                </a>
              </div>
              <div className='d-flex flex-column pe-40 '>
                <span className='text-white-50'>
                  {t(`Have any questions?`)}
                </span>
                <span className='text-white'>{t(`Call:`)} +2519 1111 4048</span>
              </div>
              <div className='d-flex align-items-center justify-content-center ms-4 '>
                <LanguageSwitcher />
              </div>
              {/* <div className='d-flex align-items-center justify-content-center ms-4 '>
                <a href='#'>
                  <i className='bi bi-search text-white fa-2x'></i>{' '}
                </a>
              </div> */}
            </div>
          </nav>
        </div>
      </div>
      {/* <!-- Navbar End --> */}
    </>
  );
};

export default Nav;
